import React, { useEffect, useContext } from "react";
import { AttemptsContext } from "../../context/AttemptsContext";
import AttemptQuestion from "../../components/tests/AttemptQuestion";
import { ModalContext } from "../../context/ModalContext";
import FinishTestForm from "../../components/tests/FinishTestForm";
import DeleteAttemptForm from "../../components/tests/DeleteAttemptForm";
import AttemptCertificate from "../../components/tests/AttemptCertificate";
import AttemptTrackingNumber from "../../components/tests/AttemptTrackingNumber";
import { getValue } from "../../utils";

const AdminSingleAttempt = ({ attempt_id }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const {
    answers,
    attempt,
    questions,
    saveAttempt,
    clearAttempt,
    finishAttempt,
    deleteAttempt,
    getSingleAttempt,
  } = useContext(AttemptsContext);

  useEffect(() => {
    clearAttempt();
    fetchAttempt();
    return clearAttempt;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempt_id]);

  const fetchAttempt = () => {
    getSingleAttempt(attempt_id);
  };

  const handleSubmit = () => {
    finishAttempt(attempt_id);
  };

  const handleSubmitDelete = () => {
    deleteAttempt(attempt_id);
  };

  const handleFinish = () => {
    modalComponent(
      "Terminar Examen",
      <FinishTestForm handleCancel={clearModal} handleSubmit={handleSubmit} />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Examen",
      <DeleteAttemptForm
        handleCancel={clearModal}
        handleSubmit={handleSubmitDelete}
      />
    );
  };
  const handleSubmitCorrections = () => {
    saveAttempt({
      ...attempt,
      request_corrrections: true,
      finishedAt: null,
      gradedAt: null,
      score: null,
    });
    clearModal();
  };

  const handleAdminCorrections = () => {
    saveAttempt({
      ...attempt,
      gradedAt: null,
    });
  };

  const handleRequestCorrections = () => {
    modalComponent(
      "Solicitar Correcciones",
      <div>
        <p>¿Estás segura que deseas solicitar correcciones?</p>
        <p>
          Esto le dará oportunidad a la alumna de cambiar sus respuestas al
          examen.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn text-muted w-100">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={handleSubmitCorrections}
              className="btn btn-primary w-100"
            >
              CORREGIR
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    if (attempt && attempt !== null) {
      return (
        <div className="row mx-0 align-items-center mb-3 pb-3 border-bottom">
          <div className="col col-md-3 px-0">
            <h1 className="h2 bold mb-0">Resultados</h1>
            {attempt.finishedAt === null ? <p className="text-danger mb-1">Sin terminar por alumna</p> : ""}
            {attempt.gradedAt !== null ? (
              <p className="mb-1">Calificado: {attempt.score}</p>
            ) : (
              <p className="mb-1 text-danger">Pendiente de calificar</p>
            )}
          </div>
          <div className="col col-md-9 px-0 text-end">
            {attempt.file_id === null && (
              <>
                <button
                  className="btn btn-outline-danger me-3"
                  onClick={handleDelete}
                  key="delete"
                >
                  <i className="fa fa-times me-2"></i>ELIMINAR
                </button>
                {attempt.finishedAt !== null && (
                  <button
                    className="btn btn-dark text-primary me-3"
                    onClick={handleRequestCorrections}
                    key="corrections"
                  >
                    <i className="fa fa-edit me-2"></i> SOLICITAR CORRECCIONES
                  </button>
                )}
              </>
            )}
            {attempt.gradedAt !== null ? (
              <button
                className="btn btn-dark text-primary"
                onClick={handleAdminCorrections}
                key="corrections"
              >
                <i className="fa fa-edit me-2"></i> CORREGIR
              </button>
            ) : (
              <button className="btn btn-primary ms-2" onClick={handleFinish}>
                <i className="fa fa-check me-2"></i>Terminar
              </button>
            )}
          </div>
        </div>
      );
    }
  };

  const renderQuestions = () => {
    if (Array.isArray(questions) && attempt && attempt !== null) {
      return questions.map((question) => (
        <AttemptQuestion
          canEdit={attempt.gradedAt === null}
          handleCallback={fetchAttempt}
          key={question.question_id}
          question={question}
          answers={answers}
        />
      ));
    }
  };

  const renderFinalExamFields = () => {
    if (attempt && attempt !== null) {
      if (attempt.test && attempt.test !== null) {
        if (attempt.test.course_id !== null && attempt.gradedAt !== null) {
          const type = getValue(attempt, "requested_diploma", "boolean")
            ? "Diploma"
            : "Certificado";
          if (
            (type === "Certificado" &&
              parseFloat(attempt.score) > parseFloat(attempt.test.min_score)) ||
            type === "Diploma"
          ) {
            return (
              <div className="card p-3 my-3">
                <div className="row">
                  <div className="col-12 col-md-6 mb-2">
                    <AttemptCertificate type={type} />
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <AttemptTrackingNumber type={type} />
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="card p-3 my-3">
                <p className="text-danger">
                  Calificado. Resultado no aprobatorio
                </p>
              </div>
            );
          }
        }
      }
    }
  };

  return (
    <div className="container-fluid">
      {renderTitle()}
      {renderFinalExamFields()}
      <div className="card p-3 shadow">{renderQuestions()}</div>
    </div>
  );
};

export default AdminSingleAttempt;
