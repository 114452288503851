import React, { useState, useEffect, useContext } from "react";
import { AttemptsContext } from "../../context/AttemptsContext";
import UserAnswerFeedbackForm from "./UserAnswerFeedbackForm";
import UserAnswerFeedbackData from "./UserAnswerFeedbackData";
import { S3_ENDPOINT, downloadFile } from "../../utils";

const AttemptQuestion = ({ canEdit, question, answers, handleCallback }) => {
  const [editContent, setEditContent] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState(null);
  const {
    attempt,
    user_answer,
    setUserAnswer,
    postUserAnswer,
    saveUserAnswer,
    setPropertyUserAnswer,
  } = useContext(AttemptsContext);

  useEffect(() => {
    const currentAnswer = findQuestionAnswer(question);
    setQuestionAnswer(currentAnswer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answers]);

  useEffect(() => {
    if (editContent) {
      setUserAnswer(questionAnswer);
    } else if(user_answer !== null && questionAnswer !== null) {
      if (questionAnswer.content !== user_answer.content) {
        saveUserAnswer(user_answer, handleCallback);
        setQuestionAnswer(user_answer);
      }
      setUserAnswer(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContent]);

  const createUserAnswer = () => {
    postUserAnswer(
      {
        question_id: question.question_id,
        attempt_id: attempt.attempt_id,
        user_id: attempt.user_id,
      },
      handleCallback
    );
  };

  const findQuestionAnswer = (question) => {
    if (Array.isArray(answers)) {
      return answers.find(
        (answer) =>
          parseInt(answer.question_id) === parseInt(question.question_id)
      );
    }
  };

  const renderIcon = (questionAnswer) => {
    if (questionAnswer.score !== null) {
      if (questionAnswer.score > 0) {
        return <i className="fa fa-check text-success me-2"></i>;
      }
      return <i className="fa fa-times text-danger me-2"></i>;
    }
  };

  const renderLeftContent = (questionAnswer) => {
    if (editContent) {
      if(user_answer === null) return <div className="spinner-border spinner-border-sm" />
      return (
        <input
          type="text"
          className="form-control"
          value={user_answer?.content}
          placeholder="Escribe el contenido..."
          onChange={(e) => setPropertyUserAnswer("content", e.target.value)}
        />
      );
    }
    let source = String(questionAnswer.content);
    if (source.includes("http")) {
      if (source.includes("you")) {
        let video_source = source;
        if (source.includes("youtu.be")) {
          video_source =
            "https://www.youtube.com/embed/" + source.split("be/")[1];
        } else {
          video_source =
            "https://www.youtube.com/embed/" + source.split("v=")[1];
        }
        return (
          <div>
            <iframe
              src={video_source}
              title="YouTube video player"
              className="mw-100 w-100 shadow mb-3"
              height="500px"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            <a
              href={source}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-accent"
            >
              <i className="fa fa-eye"></i> Ver en YouTube
            </a>
          </div>
        );
      }
      if (source.includes("drive")) {
        let video_source = source;
        if (source.includes("view")) {
          video_source = video_source.replace("view", "preview");
        }
        return (
          <div>
            <iframe
              src={video_source}
              className="mw-100 w-100 mb-3"
              height="auto"
              width="auto"
              title="video"
              style={{ minHeight: 300 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            <a
              href={source}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-accent"
            >
              <i className="fa fa-eye"></i> Ver en Drive
            </a>
          </div>
        );
      }
      return (
        <div>
          <video
            className="w-100 d-block m-auto"
            style={{ maxWidth: 500 }}
            playsInline
            controls
          >
            <source src={questionAnswer.content} type="video/mp4" />
          </video>
          <div className="my-3">
            <button
              onClick={() =>
                downloadFile(
                  questionAnswer.content,
                  String(questionAnswer.content).replace(`${S3_ENDPOINT}/`, "")
                )
              }
              className="btn btn-outline-accent shadow-sm me-2"
            >
              <i className="fa fa-download me-2"></i>
              Descargar Video
            </button>
            <a
              href={source}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-accent me-2 shadow-sm"
            >
              <i className="fas fa-external-link-alt me-2"></i>
              Abrir Enlace
            </a>
          </div>
          <label>Enlace</label>
          <input disabled type="text" value={source} className="form-control" />
        </div>
      );
    }
    return (
      <p className="px-3">
        {renderIcon(questionAnswer)} {questionAnswer.content}
      </p>
    );
  };

  const renderFeedback = (questionAnswer) => {
    if (user_answer !== null) {
      if (
        user_answer.user_answer_id === questionAnswer.user_answer_id &&
        !editContent
      ) {
        return (
          <UserAnswerFeedbackForm
            user_answer={user_answer}
            handleCallback={handleCallback}
          />
        );
      }
    }
    return (
      <UserAnswerFeedbackData
        canEdit={canEdit}
        questionAnswer={questionAnswer}
      />
    );
  };

  const renderAnswer = () => {
    if (questionAnswer !== null) {
      if (questionAnswer === undefined) {
        return (
          <p className="text-danger my-2">
            El usuario no completó esta pregunta
          </p>
        );
      }
      return (
        <div className="my-3">
          <div className="row">
            <div className="col-12 col-md-6">
              {renderLeftContent(questionAnswer)}
              <button
                onClick={() => setEditContent(!editContent)}
                className="btn btn-outline-secondary my-3"
              >
                {editContent ? (
                  <span>
                    <i className="fa fa-save me-2"></i>Guardar
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-edit me-2" />
                    Editar
                  </span>
                )}
              </button>
            </div>
            <div className="col-12 col-md-6">
              {renderFeedback(questionAnswer)}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderAnswerButton = () => {
    if (questionAnswer === undefined) {
      return (
        <button onClick={createUserAnswer} className="btn btn-primary">
          <i className="fa fa-plus" />
        </button>
      );
    }
  };

  return (
    <div className="w-100 mb-4">
      <div className="row align-items-center py-2 mx-0 border bg-light">
        <div className="col-10">
          <p className="mb-0">
            #{question.order} - {question.content}
          </p>
        </div>
        <div className="col-2 text-end">{renderAnswerButton()}</div>
      </div>
      {renderAnswer()}
    </div>
  );
};

export default AttemptQuestion;
