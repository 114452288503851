import api from "./api";
import { getArgs } from "../utils";

const route = "/attempts";

const AttemptsService = {
  getUserAttempts: (user_id, filters) =>
    api.get(`${route}/user/${user_id}?${getArgs(filters)}`),
  getTestsAttempts: (test_id) => api.get(`/test/${test_id}`),
  getFinalExamsAttempts: (filters) =>
    api.get(`${route}/admin/all?${getArgs(filters)}`),
  getSingleAttempt: (attempt_id) => api.get(`${route}/${attempt_id}`),
  postUserAnswer: (user_answer) =>
    api.post(`${route}/admin/user_answer`, { ...user_answer }),
  putUserAnswer: (user_answer) =>
    api.put(`${route}/answer`, { ...user_answer }),
  putAttempt: (attempt) => api.put(`${route}/admin`, { ...attempt }),
  finishAttempt: (attempt_id) =>
    api.put(`${route}/admin/grade`, { attempt_id }),
  postAttempt: (test_id) => api.post(route, { test_id }),
  deleteAttempt: (attempt_id) => api.delete(`${route}/admin/${attempt_id}`),
};
export default AttemptsService;
